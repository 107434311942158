<template>
  <div class="Body">
    <ModalCallConfirmation
      :isDisplayModalConfirmCall="isDisplayModalConfirmCall"
      :trigger="trigger"
      :name="name"
      :id="id"
      @closeModal="isDisplayModalConfirmCall = false"
    />
    <ModalBody v-if="showModal">
      <button class="buttonGray" @click="(event) => onChangeShowModal(event)">
        Cancelar
      </button>
      <button
        :loading="loadingFinish"
        class="buttonGreen"
        @click="() => onChangeFinishCall(finishCallType)"
      >
        {{ loadingFinish ? "Cargando..." : "Aceptar" }}
      </button>
    </ModalBody>
    <ul
      class="Table__Body"
      v-if="bodyParsed.length>0"
    >
      <li
        class="Table__Body--Item"
        v-for="(bodyItem2, index) in bodyParsed"
        :key="index"
      >
      <div class="ContainerDropdown">
          <button v-if="!currentTurn"
              :disabled="callData"
              class="Banner Table__Body--text"
              @click="(event) => onShowModalConfirmCall(event, bodyItem2, bodyParsed)"
              style="background-color: #E2F3EB; color: #30B365;" 
            >
            <span>
              LLamar
              <svg
              
                class="IconBanner"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.77988 0.229439C9.6389 0.0825295 9.44771 0 9.24837 0C9.04902 0 8.85783 0.0825295 8.71685 0.229439L4.99546 4.10846L1.27408 0.229439C1.13229 0.086693 0.94238 0.0077061 0.745262 0.00949156C0.548143 0.011277 0.359584 0.0936919 0.220195 0.238985C0.0808067 0.384278 0.00174141 0.580825 2.86102e-05 0.786293C-0.00168419 0.991761 0.0740929 1.18971 0.211038 1.33751L4.46394 5.77056C4.60492 5.91747 4.79611 6 4.99546 6C5.19481 6 5.386 5.91747 5.52698 5.77056L9.77988 1.33751C9.92082 1.19055 10 0.991266 10 0.783473C10 0.57568 9.92082 0.376394 9.77988 0.229439Z"
                />
              </svg>
            </span>
          </button>
          <button v-else
              :disabled="callData"
              class="Banner Table__Body--text" 
              style="background-color: rgb(232, 238, 252); color: rgb(186, 194, 219);"
            >
            <span>
              LLamar
              <svg
              
                class="IconBanner"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.77988 0.229439C9.6389 0.0825295 9.44771 0 9.24837 0C9.04902 0 8.85783 0.0825295 8.71685 0.229439L4.99546 4.10846L1.27408 0.229439C1.13229 0.086693 0.94238 0.0077061 0.745262 0.00949156C0.548143 0.011277 0.359584 0.0936919 0.220195 0.238985C0.0808067 0.384278 0.00174141 0.580825 2.86102e-05 0.786293C-0.00168419 0.991761 0.0740929 1.18971 0.211038 1.33751L4.46394 5.77056C4.60492 5.91747 4.79611 6 4.99546 6C5.19481 6 5.386 5.91747 5.52698 5.77056L9.77988 1.33751C9.92082 1.19055 10 0.991266 10 0.783473C10 0.57568 9.92082 0.376394 9.77988 0.229439Z"
                />
              </svg>
            </span>
          </button>
        </div>
      <p class="Table__Body--text nameText">
        {{ bodyItem2.customer?.firstName }} {{  bodyItem2.customer?.lastName }}
      </p>
      <p class="Table__Body--text nameText">
        {{  bodyItem2.customer?.dni }} 
      </p>
      <p class="Table__Body--text nameText">
       {{  toDate(bodyItem2.initTimeStamp) }} 
      </p>
      <p class="Table__Body--text nameText">
        <Counter :endDate="bodyItem2.initTimeStamp"></Counter>
      </p>
      <p class="Table__Body--text nameText">
        {{ bodyItem2.letter }}{{ bodyItem2.number }}
      </p>

    
      </li>
      
    </ul>
    <div
      class="noFound"
      v-if="
        bodyParsed.length < 1 && !getThemeMode.isLoading && !loadingMainTurns
      "
    >
      No se encontraron turnos asignados para el servicio y/o puesto
      seleccionado
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
// import _ from "lodash";

export default {
  name: "TableBodyTurns",

  data() {
    return {
      disabledAll: false,
      showModal: false,
      selectedEstado: null,
      id: "",
      name: "",
      finishCallType: null,
      loadingFinish: false,
      isDisplayModalConfirmCall: false,
      trigger: () => {},
      currentDate: moment()
    };
  },

  components: {
    // MiniSpinner: () => import("./MiniSpinner.vue"),
    ModalBody: () => import("./ModalBody.vue"),
    ModalCallConfirmation: () => import("./ModalCallConfirmation.vue"),
    Counter: () => import("./Counter.vue")
  },

  mounted() {
    window.onclick = () => {
      if (this.turns.some((item) => item.showDropdown) && !this.disabledAll) {
        this.onCleanBody();
      }
    };
  },

  watch: {
    // currentDate: {
    //   immediate: true,

    //   handler () {
    //     setInterval(() => { 
    //       this.currentDate = moment()
    //     }, 1000)
    //     // const endDateTimeObj = DateTime.fromISO(endDateTimeStr)

    //     // if (this.timer) {
    //     //   clearInterval(this.timer)
    //     // }

    //     // this.timer = setInterval(() => {
    //     //   this.now = DateTime.local()

    //     //   if (this.now > endDateTimeObj) {
    //     //     this.now = endDateTimeObj
    //     //     clearInterval(this.timer)
    //     //   }
    //     // }, 1000)
    //   }
    // } ,
    disabledAll(val) {
      document.querySelectorAll("button").forEach(($button) => {
        $button.disabled = val;
      });
    },
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      body: (state) => state.doctorPanel.body,
      buttonsAtention: (state) => state.doctorPanel.buttonsAtention,
      buttonsAbsent: (state) => state.doctorPanel.buttonsAbsent,
      pollingDataLoading: (state) => state.pollingWorkplaceSilver.loadingP,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      callData: (state) => state.loggedUserSilver.callData,
      loggedData: (state) => state.loggedUserSilver.loggedUserData,
      cupsCodes: (state) => state.doctorPanel.cupsCodes,
      turns: (state) => state.doctorPanel.turns,
      loadingMainTurns: (state) => state.doctorPanel.loadingMainTurns,
      
    }),
    currentTurn() {
      return this.loggedData.worker?.actualTurn
    },
    bodyParsed() {
      // const finalHours = {};
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //let body = this?.turns;
      let pollingData = this.pollingData?.callableQueueGroups;

     
      let callableQueueGroups = pollingData != undefined ? pollingData.map(callableQueueGroup => callableQueueGroup.turns).reduce((prev, next )=> prev.concat(next))
      :[] 

     
      return callableQueueGroups
      //return callableQueueGroups.reduce((prev, next )=> prev.concat(next))

      // merge body data
      // body = body.map((lb) => {
      //   let turnB = this.body.find(
      //     (b) => b.Turno?.turn_id == lb.Turno?.turn_id
      //   );
      //   return { ...lb, ...turnB };
      // });

      // merge arrays 
      // body = [...new Set([...this.body,...body])];
      // body = _.uniqBy(body, function (e) {
      //   return e.id;
      // });
      // body = body.filter((b) => !b.HoraAtendido).filter(b=> !b.Servicio || (b.Servicio && b.Servicio == ""));
      // body.map((b) => {
      //   // const key = item.HoraLlegada.split(":")[0];
      //   // if (finalHours[key]) {
      //   //   finalHours[key].push(item);
      //   //   finalHours[key].sort(this.sortBody);
      //   // } else finalHours[key] = [item];
      //   b.NumeroID = b.NumeroType + ' ' + b.NumeroID;
      // });

      // console.log("MOSTRANDO", body.sort())

      // return body.sort();
    },
  },

  methods: {
    ...mapActions([
      "onChangeTurns",
      "onChangeButtonsAtention",
      "onChangeButtonsAbsent",
      "callTurnActionById",
      "handlerDerivableQueueGroups",
      "pollingWorkplaceGetAction",
      "finishTurnAction",
      "revokeTurnAction",
      "resetCallData",
      "reCallTurnAction",
      "absentAction",
      "reloadLoggedUserAction"
    ]),
    timeBeen(date) {
      // let now = this.currentDate //todays date
      // let end = moment(date); // another date
      // let duration = moment(now.diff(end)).format('mm:ss');
      
      // return duration
      console.log(date)
      return moment().add(3, 'hours').toDate()
    },
    onShowModalConfirmCall(event, bodyItem2, bodyParsed) {
      if(this.callData && this.callData.code) return;
      this.isDisplayModalConfirmCall = true;
      this.name = bodyItem2?.customer?.firstName + bodyItem2?.customer?.lastName ;
      this.id = bodyItem2?.customer?.dni;
      this.trigger = () => this.onClickStatus(event, bodyItem2, bodyParsed);
    },

    sortBody(itemA, itemB) {
      // const itemBHCS = moment()
      //   .hour(itemB.HoraLlegada.split(":")[0])
      //   .minute(itemB.HoraLlegada.split(":")[1]);
      // const itemAHCS = moment()
      //   .hour(itemA.HoraLlegada.split(":")[0])
      //   .minute(itemA.HoraLlegada.split(":")[1]);
      const itemBHCS = moment(itemB.HoraCitaGrilla);
      const itemAHCS = moment(itemA.HoraCitaGrilla);
      if (
        moment().diff(itemBHCS.hour()) - moment().diff(itemAHCS.hour()) > 0 &&
        moment().diff(itemBHCS.minutes()) - moment().diff(itemAHCS.minutes()) >
          0
      ) {
        return -1;
      }
      if (
        moment().diff(itemBHCS.hour()) - moment().diff(itemAHCS.hour()) <= 0 &&
        moment().diff(itemBHCS.minutes()) - moment().diff(itemAHCS.minutes()) <=
          0
      ) {
        return -1;
      }
      return 0;
    },

    bannerStyles(status) {
      const result = {
        "No asistió": {
          styles: "background-color: #FCEAEA; color: #EC5C5C;",
        },
        Ausente: {
          styles: "background-color: #EAFCFB; color: #1BCAC0;",
          icon: "#1BCAC0",
        },
        Atendido: {
          styles: "background-color: #E8EEFC; color: #5F83F1;",
        },
        "En atención": {
          styles: "background-color: #E2F3EB; color: #30B365;",
          icon: "#30B365",
        },
        "SIN TURNO": {
          styles: "background-color: #E8EEFC; color: rgb(186 194 219);",
        },
      };

      if (this.callData) {
        return status == "En atención"
          ? result[status]
          : result["SIN TURNO"] || {};
      }

      return result[status] || {};
    },

    bannerStylesWithoutCall(status) {
      const result = {
        "No asistió": {
          styles: "background-color: #FCEAEA; color: #EC5C5C;",
        },
        Ausente: {
          styles: "background-color: #EAFCFB; color: #1BCAC0;",
          icon: "#1BCAC0",
        },
        Atendido: {
          styles: "background-color: #E8EEFC; color: #5F83F1;",
        },
        "En atención": {
          styles: "background-color: #E2F3EB; color: #30B365;",
          icon: "#30B365",
        },
        "SIN TURNO": {
          styles: "background-color: #E8EEFC; color: rgb(186 194 219);",
        },
      };

      return result[status] || {};
    },

    disabledText(status) {
      return (
        (status === "No asistió" || status === "Atendido") &&
        "Table__Body--text_disabled"
      );
    },

    async onClickStatus(event, bodyItem) {
      event.stopPropagation();
      console.log("this.loggedData", this.loggedData)
      
      let group = this.pollingData.selectedQueueGroup
      let waitingRoom = this.loggedData.worker.waitingRoom

      this.onChangeBodyHandler(bodyItem, () => ({ loading: true }));
      await this.callTurnActionById({
            "queueGroup.id": group?.id,
            "turn.id": bodyItem.id,
            "waitingRoom.id": waitingRoom.id,
      });

      await this.pollingWorkplaceGetAction();
      await this.reloadLoggedUserAction()

      // switch (bodyItem.Estado) {
      //   case "Ausente":
      //     this.disabledAll = false;
      //     this.clearAllDropdowns();
      //     if (!bodyItem.showDropdown) {
      //       this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
      //         showDropdown: !bodyItem2.showDropdown,
      //       }));
      //     }
      //     break;
      //   case "Llamar": {
      //     this.onChangeBodyHandler(bodyItem, () => ({ loading: true }));

      //     // fetch polling data
      //     await this.pollingWorkplaceGetAction();

      //     let group = this.pollingData.callableQueueGroups
      //       .filter((g) => g.turns.length > 0)
      //       .find((t) =>
      //         t.turns.map((t) => t.id).includes(bodyItem.Turno.turn_id)
      //       );

      //     // call user
      //     await this.callTurnActionById({
      //       "queueGroup.id": group?.id,
      //       "turn.id": bodyItem.Turno.turn_id,
      //       "waitingRoom.id": bodyItem.Turno.waiting_room_id,
      //     });

      //     this.$emit("finished");

      //     this.disabledAll = true;
      //     const timer = setTimeout(() => {
      //       this.onChangeBodyHandler(bodyItem, () => ({ loading: false }));
      //       this.disabledAll = false;
      //       this.onCleanBody();
      //       clearTimeout(timer);
      //     }, 1000);
      //     break;
      //   }
      //   case "En atención":
      //     this.disabledAll = false;
      //     this.clearAllDropdowns();
      //     if (!bodyItem.showDropdown) {
      //       this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
      //         showDropdown: !bodyItem2.showDropdown,
      //       }));
      //     }
      //     break;
      //   default:
      //     return;
      // }
    },

    clearAllDropdowns() {
      const copyBody = JSON.parse(JSON.stringify(this.turns));
      const copyBodyMaped = copyBody.map((item) => ({
        ...item,
        showDropdown: false,
      }));

      this.onChangeTurns(copyBodyMaped);
    },

    onCleanBody(filter) {
      const copyBody = JSON.parse(JSON.stringify(this.turns));

      const copyBodyMaped = copyBody.map((item) => {
        if (filter && filter.id === item.id) {
          return filter;
        }

        return {
          ...item,
          showDropdown: false,
        };
      });

      this.onChangeTurns(copyBodyMaped);
    },

    onChangeBodyHandler(bodyItem, newValues) {
      const copyBody = JSON.parse(JSON.stringify(this.turns));
      const copyIndex = copyBody.findIndex(
        (cbItem) => cbItem.id === bodyItem.id
      );
      const bodyItem2 = this.turns.find((cbItem) => cbItem.id === bodyItem.id);

      copyBody[copyIndex] = {
        ...bodyItem2,
        ...newValues(bodyItem2),
      };

      this.onChangeTurns(copyBody);
    },

    onChangeLoading(isAtt, item, val) {
      if (val) {
        this.disabledAll = true;
      } else {
        this.disabledAll = false;
      }

      const original = isAtt ? this.buttonsAtention : this.buttonsAbsent;
      const copyArray = JSON.parse(JSON.stringify(original));
      const itemFoundIndex = original.findIndex(
        (itemLocal) => itemLocal.id === item.id
      );
      const action = isAtt
        ? this.onChangeButtonsAtention
        : this.onChangeButtonsAbsent;
      const itemFound = original.find((itemLocal) => itemLocal.id === item.id);

      copyArray[itemFoundIndex] = {
        ...itemFound,
        loading: val,
      };

      action(copyArray);
    },

    onChangeShowModal(event) {
      event.stopPropagation();

      this.showModal = false;
    },

    onChangeFinishCall(finishCallType) {
      this.handleFinishCall(finishCallType);
    },

    async absentButtonsHandler(item, bodyItem2) {
      switch (item.Estado) {
        case "Llamar": {
          this.onChangeLoading(false, item, true);

          // fetch polling data
          await this.pollingWorkplaceGetAction();

          // call user
          await this.callTurnActionById({
            "queueGroup.id": bodyItem2.Turno.caller_group_id,
            "turn.id": bodyItem2.Turno.turn_id,
            "waitingRoom.id": bodyItem2.Turno.waiting_room_id,
          });

          this.$emit("finished");

          const timer = setTimeout(() => {
            this.onChangeLoading(false, item, false);
            this.onCleanBody();
            clearTimeout(timer);
          }, 1000);
          break;
        }
        case "No asistió":
          this.showModal = true;
          break;
      }
    },

    async atentionButtonsHandler(item) {
      this.selectedEstado = item.Estado;

      switch (item.Estado) {
        case "Volver a llamar": {
          this.onChangeLoading(true, item, true);
          await this.reCallTurnAction();
          this.onChangeLoading(true, item, false);
          this.onCleanBody();
          this.$emit("finished");
          // const timer = setTimeout(() => {
          //   this.onChangeLoading(true, item, false);
          //   // this.onChangeBodyHandler(bodyItem, (bodyItem2) => ({
          //   //   Estado: !bodyItem2.showDropdown,
          //   // }));
          //   this.onCleanBody();
          //   clearTimeout(timer);
          // }, 4000);

          break;
        }
        case "Ausente": {
          this.onChangeLoading(true, item, true);
          await this.onChangeFinishCall("absent");
          this.onChangeLoading(true, item, false);
          this.onCleanBody();
          this.$emit("finished");
          // this.onChangeLoading(true, item, true);
          // const timer = setTimeout(() => {
          //   this.onChangeLoading(true, item, false);
          //   this.onCleanBody();
          //   clearTimeout(timer);
          // }, 4000);
          break;
        }
        case "No asistió":
          this.showModal = true;
          this.finishCallType = "revoke";
          break;
        case "Atendido":
          this.showModal = true;
          this.finishCallType = true;
          break;
      }
    },

    toAMPMDate(hour, minutes) {
      return moment()
        .hour(hour)
        .minutes(minutes || 0)
        .format("hh:mma");
    },

    toDate(date) {
      return moment(date)
        .format("hh:mma");
    },

    handleFinishCall(event) {
      if (!event) {
        this.options = [];
        if (this.loggedData.worker?.actualTurn) {
          if (
            this.loggedData.worker.actualTurn.queue
            // &&
            // this.loggedData.worker.configs.typifyServiceEnabled
          ) {
            if (this.loggedData.worker.actualTurn.queue.services) {
              this.showTypify = true;
              this.options =
                this.loggedData.worker.actualTurn.queue.services || [];
            }
          }
        } else {
          if (this.callData?.queue) {
            this.showTypify = true;
            this.options = this.callData.queue?.services || [];
          }
        }
        if (this.options.length > 0) return;
      }
      return this.finishCallData(event);
    },

    async finishCallData(evaluator, service_id) {
      this.loadingFinish = true;
      if (evaluator === "absent") await this.absentAction();
      else if (evaluator === "revoke") await this.revokeTurnAction();
      else await this.finishTurnAction(service_id);
      this.showModal = false;
      this.loadingFinish = false;
      this.resetCallData();
      this.$emit("finished");
      this.onCleanBody();
    },

    checkExtra(extra, prop){
      if(!extra) return;
      try{
        let ex = JSON.parse(extra);
        ex = ex[0]?.Videollamada;
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let mex = ex.match(urlRegex);
        let params = new URLSearchParams(mex[0]);
        let pr = params.get('pr');
        let pr_b = atob(pr.replace('"', ''));
        let pr_f = decodeURIComponent(pr_b);
        let jsonExtra = JSON.parse(pr_f);
        if(prop == 'NumeroID'){
          return `${jsonExtra.ti} ${jsonExtra.nu}`
        }
        if(prop == 'Nombre'){
          return jsonExtra.na ? `${jsonExtra.na} ${jsonExtra.la}` : 'N/R';
        }
        return jsonExtra[prop] || 'N/R';

      } catch (e){
        console.error(e)
        return "";
      }
    }
  },
};
</script>

<style scoped>
.Table__Body {
  position: relative;
  padding: 0;
  grid-row: 2 / 3;
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(65px, 1fr);
  grid-auto-rows: minmax(65px, 1fr);
  border-bottom: 1px solid rgba(44, 66, 108, 0.6);

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.Table__Body:last-child {
  border: none;
}

.Table__Body--Item {
  position: relative;
  display: grid;
  justify-items: start;
  align-items: center;
  gap: 10px;
  grid-template-columns:
    repeat(2, var(--grid-minmax))
    minmax(250px, 350px) repeat(5, var(--grid-minmax)); /* TODO: 3. Change me to 8 and enable SMS column */
  grid-template-rows: 1fr;
  border-bottom: 1px solid #eff1f5;
  list-style: none;
  width: 100%;
}

.Banner {
  background-color: #fcf2e9;
  color: #f5a462;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.nameText {
  text-transform: capitalize;
}

.cedulaText {
  text-transform: lowercase;
}

.IconBanner {
  margin-left: 6px;
  transition: 0.5s transform;
}

.IconBannerRotated {
  transform: rotate(-180deg);
}

.Banner:hover {
  opacity: 0.8;
}

.Table__Body--text_disabled {
  opacity: 0.5;
}

.starHour {
  position: absolute;
  /* left: -50.44px; */
  left: -70px;
  top: -10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}

.buttonSendSMS {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-panel-secondary);
  border-radius: 4px;
}

.ContainerDropdown {
  position: relative;
}

.DropdownBox {
  width: 247px;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: -62px;
  background: #ffffff;
  z-index: 900005;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 3px 9px 9px rgba(0, 0, 0, 0.3);
  transition: 0.25s all;
}

.buttonSendSMS:disabled {
  opacity: 35%;
}

.Table__Body--text {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.AttDropdown {
  bottom: -252px;
}

.AbsentDropdown {
  /* bottom: -158px; */
  bottom: -106px;
}

.ButtonDropdown {
  margin-bottom: 16px;
}

.ButtonDropdown:last-child {
  margin-bottom: 9px;
}

.MiniSpinner__Container {
  margin-left: 5px;
}

.noFound {
  width: 100%;
  text-align: center;
  padding: 40px;
}
</style>
